import React, { useEffect, useRef, useState } from "react";
import MacBookReto from "../assets/macBookReto.svg";
import iconPlat from "../assets/icon-Plat.svg";
import iconOne from "../assets/iconOnePlat.svg";
import iconTwo from "../assets/iconTwoPlat.svg";
import iconTree from "../assets/IconTreePlat.svg";
import Funcionalidade from "../assets/Funcionalidades.svg";
import FuncionalidadeResp from "../assets/funcResponsivo.svg";
import dashBoards from "../assets/dashS.svg";
import CarroMotionPath from "../assets/carroMotionPath.svg"
import {carMotionPath, runAfterLoad} from "./Animacoes.js"
import {gsap} from "gsap";

import "./Plataforma.css";

export default function Plataforma() {



  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);
  const [svgViewBox, setSvgViewBox] = useState("0 0 229 1332");
  const [svgWidth, setSvgWidth] = useState(229);
  const [svgHeight, setSvgHeight] = useState(1332);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
    script.onload = () => {
      if (window.RDStationForms) {
        const formContainer = document.getElementById('novo-lead-site-18d99087cd5f9a0faba1');
        if (formContainer) {
          new window.RDStationForms('novo-lead-site-18d99087cd5f9a0faba1', null).createForm();
        }
      }
    };
    document.body.appendChild(script);
  }, [showForm]);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (showForm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showForm]);


  useEffect(() =>{

    return runAfterLoad(()=>{

      if(window.innerWidth <= 768){
        setSvgViewBox("0 0 229 900");
        setSvgHeight(900);
      }
      var animacoes = gsap.context(() =>{
        
      carMotionPath("#carroBGSolucoes", "#backGroundSolucoes1Path", "#backGroundSolucoes1", "top top", "90% top", false)
  
      carMotionPath("#carroBGGrande", "#backGroundSolucoesGrandePath", "#backGroundSolucoesGrande", "top 10%", "90% 20%", false)
  
      })
  
      console.log(animacoes)
  
      return () =>{
        animacoes.revert()
  
      }
    })

  },[])

  return (
    <>

      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={formRef} className=" z-10 rounded-lg shadow-lg">
            <div role="main" id="novo-lead-site-18d99087cd5f9a0faba1"></div>
          </div>
        </div>
      )}


      <div id="backGroundSolucoes1" className="backGroundSolucoes1 flex items-center  justify-center mb-5 md:mb-0 ">
        <svg id="backGroundSolucoes1Svg" width="172" height="403" viewBox="0 0 172 403" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_1486_2527)">
          <path id="backGroundSolucoes1Path" d="M181.57 15.495C127.424 61.9563 18.6482 171.424 16.7183 237.603C13.9116 333.844 85.4992 381.426 107.478 409.749" stroke="#0B40B7" stroke-opacity="0.5" stroke-width="4.53166" shape-rendering="crispEdges"/>
          </g>
          <g filter="url(#filter1_d_1486_2527)">
          <circle cx="9.96965" cy="9.96965" r="9.96965" transform="matrix(-1 0 0 1 86.8594 113.379)" fill="#0B40B7"/>
          </g>
          <defs>
          <filter id="filter0_d_1486_2527" x="0.780025" y="0.180416" width="195.862" height="424.553" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6.79749"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2527"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2527" result="shape"/>
          </filter>
          <filter id="filter1_d_1486_2527" x="52.5079" y="98.9648" width="48.7654" height="48.7672" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7.20697"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2527"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2527" result="shape"/>
          </filter>
          </defs>
        </svg>
        <img id="carroBGSolucoes" className="carros" src={CarroMotionPath} alt="Carro da animação"/>

        <div className="z-0 flex items-center  justify-around  mt-10 mx-20">
          <div className="md:ml-20 w-full  ">
            <h3 className="font-medium text-center md:text-start text-sm md:text-xl mb-2 md:mb-3">
              A plataforma ideal para sua empresa:
            </h3>
            <h1 className="text-4xl text-center md:text-start md:text-6xl titlePlataforma fontBebasNeue ">
            OFERECEMOS TECNOLOGIA E SEGURANÇA PARA VOCÊ E SEU CLIENTE
            </h1>
            <div className="w-full flex justify-center md:flex md:justify-start mt-4">
              <img className=" w-40" src={iconPlat} alt="" />
            </div>
          </div>
          <div className=" hidden md:flex justify-center w-full">
            <img className="w-5/6" src={MacBookReto} alt="MacBook" />
          </div>
        </div>
      </div>

      <div className="z-0 bg-Plat flex flex-col md:flex-row justify-center items-center gap-3 md:gap-20">
        <div className="my-10 md:my-20 bg-white p-2 md:p-10 h-56 md:h-72 w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-4 md:w-8" src={iconOne} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20"> </div>
          <div className="titlePlataforma font-bold  text-lg md:text-2xl text-center tracking-tighter">
            <p>RELÁTORIOS</p>
            <span>COMPLETOS</span>
          </div>
        </div>
        <div className="my-10 md:my-20 bg-white p-2  md:p-10 h-56 md:h-72 w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-5 md:w-10" src={iconTwo} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20 "> </div>
          <div className="titlePlataforma font-bold  text-lg md:text-2xl text-center tracking-tighter">
            <p>DASHBOARD</p>
            <span>MODERNO</span>
          </div>
        </div>
        <div className="my-10 md:my-20 bg-white p-2 md:p-10 h-56 md:h-72 w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-6 md:w-12" src={iconTree} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20"> </div>
          <div className="titlePlataforma font-bold  text-lg md:text-2xl text-center w150 tracking-tighter">
            <p>CONTROLE DOS</p>
            <span>DISPOSITIVOS</span>
          </div>
        </div>
      </div>
      <div id="backGroundSolucoesGrande" className="backgroundGrandePlat">
      <svg id="backGroundSolucoesGrandeSvg" width={svgWidth} height={svgHeight} viewBox={svgViewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1486_2541)">
        <path id="backGroundSolucoesGrandePath" d="M140.71 -15.8061C158.385 112.44 46.027 267.535 42.074 333.608C36.3253 429.697 80.4198 564.533 97.6472 593.005C187.069 855.861 37.8959 1118.4 -5.96305 1317.63" stroke="#0B40B7" stroke-opacity="0.5" stroke-width="4.53166" shape-rendering="crispEdges"/>
        </g>
        <g filter="url(#filter1_d_1486_2541)">
        <circle cx="9.96965" cy="9.96965" r="9.96965" transform="matrix(-0.999996 -2.40623e-05 2.40657e-05 1 53.7344 315.003)" fill="#0B40B7"/>
        </g>
        <g filter="url(#filter2_d_1486_2541)">
        <circle cx="9.96965" cy="9.96965" r="9.96965" transform="matrix(-0.999996 -2.40623e-05 2.40657e-05 1 109.93 584.184)" fill="#0B40B7"/>
        </g>
        <g filter="url(#filter3_d_1486_2541)">
        <circle cx="9.96965" cy="9.96965" r="9.96965" transform="matrix(-0.999996 -2.40623e-05 2.40657e-05 1 83.6406 1045.05)" fill="#0B40B7"/>
        </g>
        <defs>
        <filter id="filter0_d_1486_2541" x="-21.7747" y="-29.7654" width="180.229" height="1361.42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="6.79749"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2541"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2541" result="shape"/>
        </filter>
        <filter id="filter1_d_1486_2541" x="19.3829" y="300.589" width="48.7654" height="48.7673" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="7.20698"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2541"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2541" result="shape"/>
        </filter>
        <filter id="filter2_d_1486_2541" x="75.5782" y="569.769" width="48.7654" height="48.7673" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="7.20698"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2541"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2541" result="shape"/>
        </filter>
        <filter id="filter3_d_1486_2541" x="49.2892" y="1030.64" width="48.7654" height="48.7673" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="7.20698"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2541"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2541" result="shape"/>
        </filter>
        </defs>
      </svg>

        <img id="carroBGGrande" className="carros" src={CarroMotionPath} alt="Carro da animação"/>

        <div className="p-4">
          <h1 className="titlePlataforma font-bold text-2xl mx-3 md:mx-0 md:textPerso tracking-tighter text-center mt-10 md:mt-20">
            O DASHBOARD PERFEITO PARA SUA GESTÃO
          </h1>
          <div className="w-full my-2 flex justify-center md:hidden">
            <img className="w-10/12" src={FuncionalidadeResp} alt="" />
          </div>
          <div className="hidden w-full md:flex items-center justify-center mt-10">
            <img className="w-10/12" src={Funcionalidade} alt="" />
          </div>
          <div className="itens-center justify-center flex my-10 md:mt-32">
              <button 
              onClick={handleButtonClick}  
              className="text-gradientButton font-bold px-8 py-4 rounded-full hover:scale-110  duration-500">
                Fale com um especialista
              </button>
          
          </div>
        </div>
        <div className="w-full hidden md:flex items-center justify-center my-12">
          <img src={dashBoards} className="w-9/12" alt="" />
        </div>
      </div>
    </>
  );
}
