import React, { useEffect, useRef, useState } from 'react'
import "./Sdriver.css"
import "./Integracao.css"

import integracoes from "../assets/integracoes.svg"
import maps from "../assets/mapsIntegracoes.svg"
import gestaoRastreio from "../assets/gestaoRastreio.svg"
import hinova from "../assets/hinova.svg"
import mapbox from "../assets/mapbox.svg"
import segware from "../assets/segware.svg"
import webhook from "../assets/webhook.svg"
import alelo from "../assets/alelo.svg"
import selsyn from "../assets/integracoes_selsyn.svg"
import { gsap } from "gsap";

const Integracao = () => {


  const formRef = useRef(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
    script.onload = () => {
      if (window.RDStationForms) {
        const formContainer = document.getElementById('novo-lead-site-18d99087cd5f9a0faba1');
        if (formContainer) {
          new window.RDStationForms('novo-lead-site-18d99087cd5f9a0faba1', null).createForm();
        }
      }
    };
    document.body.appendChild(script);
  }, [showForm]);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (showForm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showForm]);


  useEffect(() =>{

    var integracoes = gsap.utils.toArray('.animacao_rotacao')

    integracoes.forEach((integracao, index) => {
      let anim=gsap.to(integracao, {
        ease: "none",
        duration: 21,
        repeat: -1,
        rotation:0,
        motionPath: {
          path: '#path_circulo',
          align: '#path_circulo',
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
        },
        onStart: () => {
          gsap.set(integracao, { opacity: 1 });
          anim.progress(0.1428571428571429 * (index+1))
        }
      })
      
    })




  },[])

  return (

    <>
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={formRef} className=" z-10 rounded-lg shadow-lg">
            <div role="main" id="novo-lead-site-18d99087cd5f9a0faba1"></div>
          </div>
        </div>
      )}

      <div className='backgroundGrandeSdriver'>
        <div className=" mx-0 z-0 my-16  h-5/12 md:z-0 flex justify-center  md:mx-20 md:mt-40 ">
          <div className=" md:ml-24 p-6 w-full">
            <span className='flex justify-center text-base md:justify-normal md:text-xl'>A Selsyn possui</span>
            <h1 className="text-center text-gradient text-4xl md:text-left md:text-6xl fontBebasNeue ">
              SOLUÇÕES INTEGRADAS
            </h1>
            <span className='flex justify-center text-base md:justify-normal md:text-xl'>com as melhores tecnologias do mercado</span>
            <div className="flex justify-center md:justify-start">
              <button
                onClick={handleButtonClick}
                className="text-gradientButton md:text-gradientButton font-bold mt-6 px-8 py-4 rounded-full hover:scale-110 duration-500"
              >
                QUERO FAZER PARTE
              </button>
            </div>
          </div>
          <div className="hidden md:flex -mt-16 w-full ">
            <div id='animacao_solucoes_wrapper'>
              <img className='animacao_solucoes' src={selsyn} alt="Logo Selsyn" id="selsynLogo" />

              <img id='alelo' className='animacao_solucoes animacao_rotacao' src={alelo} alt="Logo Alelo" />
              <img id='maps' className='animacao_solucoes animacao_rotacao' src={maps} alt="Logo Alelo" />
              <img id='hinova' className='animacao_solucoes animacao_rotacao' src={hinova} alt="Logo Alelo" />
              <img id='gestaoRastreio' className='animacao_solucoes animacao_rotacao' src={gestaoRastreio} alt="Logo Alelo" />
              <img id='webhook' className='animacao_solucoes animacao_rotacao' src={webhook} alt="Logo Alelo" />
              <img id='mapbox' className='animacao_solucoes animacao_rotacao' src={mapbox} alt="Logo Alelo" />
              <img id='segware' className='animacao_solucoes animacao_rotacao' src={segware} alt="Logo Alelo" />


              <svg className='animacao_solucoes' width="395" height="395" viewBox="0 0 395 395" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id='path_circulo' 
                  d="M 197.5,3.582 A 193.918,193.918 0 1,1 197.5,391.418 A 193.918,193.918 0 1,1 197.5,3.582" 
                  transform="rotate(164.469 197.5 197.5)" 
                  stroke="url(#paint0_linear_2088_2470)" 
                  stroke-width="6.07647" 
                  fill="none"/>
                
                <defs>
                  <linearGradient id="paint0_linear_2088_2470" x1="197.5" y1="0.544159" x2="197.5" y2="394.456" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0A4AD5"/>
                    <stop offset="1" stop-color="#0A4AD5" stop-opacity="0.34"/>
                  </linearGradient>
                </defs>
              </svg>

            </div>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={maps} alt="" />
          </div>
          <div className='w-full'>
            <h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Google Maps</h4>
            <span className='text-sm'>Camadas de mapas e endereços</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={gestaoRastreio} alt="" />
          </div>
          <div className='w-full'>
            <a href="https://gestaorastreio.com.br/central-de-rastreamento/" target="_blank"><h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Gestão rastreio</h4></a>
            <span className='text-sm'>Gestão financeira</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={hinova} alt="" />
          </div>
          <div className='w-full'>
            <h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Hinova - <a href="https://hinova.com.br/hinova-solucoes-digitais/sga/" target='_blank'>SGA</a>  e <a href="https://hinova.com.br/hinova-solucoes-digitais/sgr/" target="_blank" rel="noopener noreferrer">SGR</a></h4>
            <span className='text-sm'>Gerenciamento administrativo</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={mapbox} alt="" />
          </div>
          <div className='w-full'>
            <a href="http://www.mapbox.com" target="_blank" rel="noopener noreferrer"> <h4 className=' font-bold text-gradient text-left text-xl md:text-3xl'>Mapbox</h4></a>
            <span className='text-sm'>Tecnologia de localização</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={segware} alt="" />
          </div>
          <div className='w-full'>
            <a href="https://segware.com/integradores/" target="_blank" rel="noopener noreferrer"><h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Segware</h4></a>
            <span className='text-sm'>Sistema Integrado para gestão e monitoramento de alertas</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={webhook} alt="" />
          </div>
          <div className='w-full'>
            <h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Webhook</h4>
            <span className='text-sm'>Comunicação leve orientada por eventos que envia dados automaticamente entre aplicativos via HTTP</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={alelo} alt="" />
          </div>
          <div className='w-full'>
           <a href="https://www.alelo.com.br" target="_blank" rel="noopener noreferrer"><h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Alelo</h4></a> 
            <span className='text-sm'>Cartão frotas</span>
          </div>
        </div>


      </div>
    </>
  )
}

export default Integracao