import React, { useEffect, useRef, useState } from "react";
import iconSpro from "../assets/spro.svg";
import appStore from "../assets/appStore.svg";
import playStore from "../assets/playStore.svg";
import iphone from "../assets/iphoneS.svg";
import ignicao from "../assets/ignicao.svg";
import pointMapa from "../assets/pointMapa.svg";
import historico from "../assets/historic.svg";
import velocidade from "../assets/velocidade.svg";
import cerca from "../assets/cercaVirtual.svg";
import bloqueio from "../assets/Bloqueio.svg";
import iphoneColors from "../assets/iphoneXmultiColo.svg";
import qualidades from "../assets/qualidadesSpro.svg";
import textGrup from "../assets/grupTextSpro.svg";
import textGrupMaior from "../assets/textGrupMaior.svg";
import qualidadesResponsiva from "../assets/qualidadesResponsive.svg";
import CarroMotionPath from "../assets/carroMotionPath.svg"
import {carMotionPath, runAfterLoad} from "./Animacoes.js"
import {gsap} from "gsap";

import "./Spro.css";

export default function Spro() {
  const [hasBeenVisible, setHasBeenVisible] = useState(false);
  const iphoneColorsMobileRef = useRef(null);
  const iphoneColorsDesktopRef = useRef(null);




  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
    script.onload = () => {
      if (window.RDStationForms) {
        const formContainer = document.getElementById('novo-lead-site-18d99087cd5f9a0faba1');
        if (formContainer) {
          new window.RDStationForms('novo-lead-site-18d99087cd5f9a0faba1', null).createForm();
        }
      }
    };
    document.body.appendChild(script);
  }, [showForm]);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (showForm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showForm]);

  const handleScroll = () => {
    const mobileContainer = iphoneColorsMobileRef.current;
    const desktopContainer = iphoneColorsDesktopRef.current;
    const visibleAmount = window.innerHeight * 0.5;

    let isVisible = false;

    if (mobileContainer) {
      const rect = mobileContainer.getBoundingClientRect();
      isVisible = rect.top <= window.innerHeight - visibleAmount && rect.bottom >= visibleAmount;
    }

    if (!isVisible && desktopContainer) {
      const rect = desktopContainer.getBoundingClientRect();
      isVisible = rect.top <= window.innerHeight - visibleAmount && rect.bottom >= visibleAmount;
    }

    setHasBeenVisible(isVisible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() =>{

    return runAfterLoad(()=>{

      let animacoes= gsap.context(()=>{
        if(window.innerWidth <= 768){
          carMotionPath("#carroSproGrande", "#backgroundGrandeSproPath", "#backgroundGrandeSpro", "30% 60%", "bottom 60%", false)
          carMotionPath("#carroSpro", "#backgroundSproPath", "#backgroundSpro", "-40% top", "bottom top", false)
        }else{
          carMotionPath("#carroSproGrande", "#backgroundGrandeSproPath", "#backgroundGrandeSpro", "30% 60%", "bottom 25%", false)
          carMotionPath("#carroSpro", "#backgroundSproPath", "#backgroundSpro", "40% 45%", "bottom 25%", false)
        }
      })

      return ()=>{
        animacoes.revert()
      }

    })



  },[])

  return (
    <>

      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={formRef} className=" z-10 rounded-lg shadow-lg">
            <div role="main" id="novo-lead-site-18d99087cd5f9a0faba1"></div>
          </div>
        </div>
      )}
      <div id="backgroundSpro" className="backgroundSpro flex items-center justify-center pb-6 md:mb-0">

        <svg id="backgroundSproSvg" width="269" height="546" viewBox="0 0 269 546" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_1486_2773)">
          <path id="backgroundSproPath" d="M274.999 36.0001C236.924 160.312 132.181 156.66 114.601 220.472C89.0344 313.271 128.621 434.702 143.244 467.424C191.303 631.392 141.725 554.829 154 563" stroke="#0B40B7" stroke-opacity="0.5" stroke-width="4.53044" shape-rendering="crispEdges"/>
          </g>
          <g filter="url(#filter1_d_1486_2773)">
          <circle cx="9.96697" cy="9.96697" r="9.96697" transform="matrix(-0.953242 -0.302209 -0.302209 0.953242 130.477 206.798)" fill="#0B40B7"/>
          </g>
          <g filter="url(#filter2_d_1486_2773)">
          <circle cx="9.96697" cy="9.96697" r="9.96697" transform="matrix(-0.953242 -0.302209 -0.302209 0.953242 155.852 463.674)" fill="#0B40B7"/>
          </g>
          <defs>
          <filter id="filter0_d_1486_2773" x="90.3462" y="21.7454" width="200.409" height="567.974" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6.79566"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2773"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2773" result="shape"/>
          </filter>
          <filter id="filter1_d_1486_2773" x="93.5821" y="188.907" width="48.7576" height="48.7595" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7.20504"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2773"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2773" result="shape"/>
          </filter>
          <filter id="filter2_d_1486_2773" x="118.957" y="445.783" width="48.7576" height="48.7595" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7.20504"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2773"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2773" result="shape"/>
          </filter>
          </defs>
        </svg>
        <img id="carroSpro" className="carros" src={CarroMotionPath} alt="Carro da animação"/>

        <div className="flex items-center text-center justify-center md:justify-between w-full">
          <div className="md:ml-36 w-full p-6 md:p-0 ">
            <h3 className="font-medium text-sm md:text-left md:text-xl mb-3">
              Leve a sua empresa para um novo patamar com
            </h3>
            <h1 className="text-4xl md:text-6xl md:text-left titlePlataforma mx-4 md:mx-0 fontBebasNeue">
              NOSSO APLICATIVO S-PRO
            </h1>
            <div className="flex ml-16 md:ml-0 mt-4 md:mt-8 gap-2">
              <div className="w-28 ">
                <img className="w-full" src={iconSpro} alt="Icon S-Pro" />
              </div>
              <div className="flex gap-2">
                <div className="w-4/12">    
                  <a
                  target="_blank"
                  href="https://apps.apple.com/br/app/s-pro/id1458113409">
                  <img className="w-full" src={appStore} alt="AppStore" />
                </a>
                </div>
                <div className="w-4/12">
                  <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=br.com.appselsyn.spro&hl=pt&pli=1">
                  <img className="w-full" src={playStore} alt="PlayStore" />
                </a></div>
              </div>
            </div>
          </div>
          <div className=" hidden md:flex justify-start w-full ">
            <img className="w-4/6" src={iphone} />
          </div>
        </div>
      </div>
      <div className="bg-frota">
        <div className="">
          <h1 className="font-bold text-xl md:text-4xl text-center pt-10 md:pt-20 text-white">
            TENHA O CONTROLE DA SUA FROTA
          </h1>
        </div>
        <div className="grid grid-cols-3 text-white py-16 md:py-32">
          <div className="flex flex-col items-center">
            <img className="w-6/12 md:w-2/12" src={ignicao} alt="" />
            <p className="text-sm md:text-lg text-center md:w-44">
              Receba <strong>alertas de ignição</strong>
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-6/12 md:w-2/12" src={pointMapa} alt="" />
            <p className="text-sm md:text-lg text-center md:w-52">
              Acompanhe em <strong>tempo real a posição</strong>
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-6/12 md:w-2/12" src={historico} alt="" />
            <p className="text-sm md:text-lg text-center md:w-52">
              <strong>Histórico do trajeto</strong> na palma da sua mão
            </p>
          </div>
          <div className="flex flex-col items-center mt-10">
            <img className="w-6/12 md:w-2/12" src={velocidade} alt="" />
            <p className="text-sm md:text-lg text-center md:w-40">
              Acompanhe a <strong>velocidade</strong>
            </p>
          </div>
          <div className="flex flex-col items-center mt-10">
            <img className="w-6/12 md:w-2/12" src={cerca} alt="" />
            <p className="text-sm md:text-lg text-center md:w-40">
              Visualize as <strong>cercas virtuais</strong>
            </p>
          </div>
          <div className="flex flex-col items-center mt-10">
            <img className="w-6/12 md:w-2/12" src={bloqueio} alt="" />
            <p className="text-sm md:text-lg text-center md:w-52">
              <strong>Bloqueie seu veiculo</strong> a qualquer momento
            </p>
          </div>
        </div>
      </div>
      <div id="backgroundGrandeSpro" className="backgroundGrandeSpro">
        <svg id="backgroundGrandeSproSvg" width="179" height="378" viewBox="0 0 179 378" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_1486_2700)">
          <path id="backgroundGrandeSproPath" d="M-1.82031 15.5652C52.3117 62.014 161.058 171.452 162.987 237.613C165.793 333.829 94.225 381.398 72.2524 409.713" stroke="#0B40B7" stroke-opacity="0.5" stroke-width="4.53044" shape-rendering="crispEdges"/>
          </g>
          <g filter="url(#filter1_d_1486_2700)">
          <circle cx="102.834" cy="123.389" r="9.96697" fill="#0B40B7"/>
          </g>
          <defs>
          <filter id="filter0_d_1486_2700" x="-16.8882" y="0.254752" width="195.815" height="424.439" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6.79566"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2700"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2700" result="shape"/>
          </filter>
          <filter id="filter1_d_1486_2700" x="78.4571" y="99.0124" width="48.7576" height="48.7541" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7.20504"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.697248 0 0 0 0 1 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1486_2700"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1486_2700" result="shape"/>
          </filter>
          </defs>
        </svg>
        <img id="carroSproGrande" className="carros" src={CarroMotionPath} alt="Carro da animação"/>

        <div className="flex justify-center mt-5 md:mt-0 md:items-center md:h-screen md:gap-16 md:px-20">
          <img
            className={`hidden md:block w-4/12 transition-opacity duration-500 ${hasBeenVisible ? "opacity-100" : "opacity-0"}`}
            src={iphoneColors}
            alt=""
            ref={iphoneColorsDesktopRef}
          />

          <div className="w-full">
            <h1 className="font-bold text-xl md:text-4xl titlePlataforma text-center md:text-left mb-10">
              TENHA SEU APP PERSONALIZADO
            </h1>
            <div className="block ml-0">
              <p className="w-11/12 mx-4 md:mx-0 md:w-full font-semibold whitespace-break-spaces">
                <span className="text-sm md:text-lg">
                  Com base nas suas necessidades e especificações, incorporamos os elementos
                </span>
                <span className="text-sm md:text-lg">
                  &nbsp;fundamentais que destacam a identidade da sua empresa.
                </span>
                <span className="text-sm md:text-lg">
                  <span className="titlePlataforma font-bold text-sm md:text-lg">
                    &nbsp; Investir nessa personalização é estratégico para se destacar no mercado de rastreamento
                  </span>
                  , atraindo mais clientes e construindo uma imagem sólida.
                </span>
              </p>
              <div className="w-full flex justify-center md:hidden">
                <img className="w-11/12" src={textGrupMaior} alt="" />
              </div>
            </div>
            <div className="flex md:hidden mt-8">
              <div className="w-full my-10">
                <img
                  className={`block md:hidden transition-opacity duration-500 ${hasBeenVisible ? "opacity-100" : "opacity-0"}`}
                  src={iphoneColors}
                  alt=""
                  ref={iphoneColorsMobileRef}
                />
              </div>
              <div className="flex flex-col items-center">
                <div className="w-9/12 mt-10">
                  <img src={qualidadesResponsiva} alt="" />
                </div>
                <button
                  onClick={handleButtonClick}
                  className="text-gradientButton text-xs py-3 px-3 rounded-full mb-5 font-bold mt-5 hover:scale-110 duration-500">
                  Quero meu App Personalizado
                </button>

              </div>
            </div>
            <div className="hidden md:flex justify-end mt-10">
              <img className="w-8/12" src={qualidades} alt="Qualidades" />
            </div>
          </div>
        </div>
        <div className="hidden md:flex w-full flex-col justify-center items-center -mt-24">
          <img className="w-7/12" src={textGrup} alt="" />

          <button
            onClick={handleButtonClick}
            className="text-gradientButton py-4 px-8 rounded-full font-bold my-16 hover:scale-110 duration-500">
            Quero meu App Personalizado
          </button>
        </div>
      </div>
    </>
  );
}
