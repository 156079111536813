import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
gsap.registerPlugin(ScrollTrigger,MotionPathPlugin);


export function runAfterLoad(callback) {
  const onPageLoad = () => {
    callback(); // Executa a função passada como argumento
  };

  if (document.readyState === "complete") {
    onPageLoad();
    console.log('carregada')
  } else {
    window.addEventListener("load", onPageLoad);
    return () => window.removeEventListener("load", onPageLoad);
  }
}

export function carMotionPath(car, path, trigger, start, end, markers){
    gsap.to(car,{opacity:1})
    let animation = gsap.to(car, {
      ease: "none",
      motionPath: {
        path: path,
        align: path,
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      scrollTrigger: {
        trigger: trigger,
        scrub: 1,
        start: start,
        end: end,
        markers: markers
      }
    });
  
    return animation
  }

export function moveFrom(elemento, x, opacity, stagger, duration){
  var animacao = gsap.from(elemento,{
      x: x,
      opacity:opacity,
      stagger:stagger,
      duration:duration,
    }) 

  return animacao
}

export function createScrollTimeline(trigger, start, end, markers = false, scrub = null,) {
  return gsap.timeline({
    scrollTrigger: {
      trigger: trigger,
      start: start,
      end: end,
      ...(scrub !== null && { scrub }),
      markers: markers, 
    },
  });
}
