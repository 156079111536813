import React, { useEffect, useRef, useState } from 'react'
import iconSdriver from '../assets/iconSdriver.svg'
import appStore from "../assets/appStore.svg";
import playStore from "../assets/playStore.svg";
import iphoneSdriver from "../assets/iphoneSdriver.svg";
import sdriverFeature from "../assets/appSdriverFeatures.svg"
import sdriverCelular from "../assets/celular-SDriver.svg"
import bgSdriverCelular from "../assets/bg-celular-SDriver.svg"

import qtdMotorista from "../assets/qtdMotoristaSdriver.svg"
import celResponsivo from "../assets/CelResponsivo.svg"
import { moveFrom, createScrollTimeline } from './Animacoes';
import { gsap } from 'gsap/gsap-core';

import './Sdriver.css'
export default function Sdriver() {




    const [showForm, setShowForm] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
        script.onload = () => {
            if (window.RDStationForms) {
                const formContainer = document.getElementById('novo-lead-site-18d99087cd5f9a0faba1');
                if (formContainer) {
                    new window.RDStationForms('novo-lead-site-18d99087cd5f9a0faba1', null).createForm();
                }
            }
        };
        document.body.appendChild(script);
    }, [showForm]);

    const handleButtonClick = () => {
        setShowForm(!showForm);
    };
    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setShowForm(false);
        }
    };

    useEffect(() => {
        if (showForm) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showForm]);

      useEffect(() =>{
        const tlAnimaçãoSdriver = createScrollTimeline("#animacao_sdriver","center 60%","bottom bottom");

        tlAnimaçãoSdriver.from("#celularSdriver",{ x: 70, opacity:0, duration:1})
        .from("#bgCelularSdriver",{x: -500, opacity:0, duration:2})

        return () => {
            tlAnimaçãoSdriver.revert(); 
        };
      },[])

      useEffect(() =>{
        const tlAnimaçãoSdriver = createScrollTimeline("#quantidadeDeMotorista","top center","center center");
        tlAnimaçãoSdriver.from("#qtdMotorista",{ x: 400, opacity:0, duration:1.5})

      },[])

    return (
        <>
            {showForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div ref={formRef} className=" z-10 rounded-lg shadow-lg">
                        <div role="main" id="novo-lead-site-18d99087cd5f9a0faba1"></div>
                    </div>
                </div>
            )}
            <div className='backgroundGrandeSdriver'>
                <div className="flex items-center text-center justify-center md:justify-between w-full ">
                    <div className="md:ml-36 p-6">
                        <h3 className="font-medium text-sm md:text-left md:text-xl mb-3">
                            Tenha o controle da jornada do seu motorista com
                        </h3>
                        <h1 className="text-4xl md:text-6xl md:text-left titlePlataforma mx-4 md:mx-0 fontBebasNeue">
                            NOSSO APLICATIVO S-DRIVER
                        </h1>
                        <div className="flex ml-16 md:ml-0 mt-4 md:mt-8 gap-2">
                            <div className="w-28 ">
                                <img className="w-full" src={iconSdriver} alt="Icon S-Pro" />
                            </div>
                            <div className="flex gap-2">
                                <div className="w-4/12">
                                    <a
                                        target="_blank"
                                        href="https://apps.apple.com/br/app/s-driver/id6511245477">
                                        <img className="w-full" src={appStore} alt="AppStore" />
                                    </a>
                                </div>
                                <div className="w-4/12">
                                    <a
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=br.com.appselsyn.motorista&hl=pt_BR">
                                        <img className="w-full" src={playStore} alt="PlayStore" />
                                    </a></div>
                            </div>
                        </div>
                    </div>
                    <div className=" hidden md:flex justify-center w-full ">
                        <img className="w-6/12" src={iphoneSdriver} />
                    </div>
                </div >
                <div className='flex justify-center md:hidden mb-5'>
                    <img className="w-full" src={celResponsivo} alt="" />
                </div>
                <div className="hidden md:block w-full">
                    <div id="animacao_sdriver">
                        <img id='celularSdriver' src={sdriverCelular} alt="Celular S-Driver"/>
                        <img id='bgCelularSdriver' src={bgSdriverCelular} alt="Background do Celular S-Driver"/>
                    </div>
                </div>

                <div id='quantidadeDeMotorista' className="">
                    <div className="flex justify-center items-center mb-5 md:mb-20">
                        <div className="w-full px-4 md:ml-24 block ">
                            <h1 className="font-bold text-2xl md:text-3xl titlePlataforma text-center md:text-left mb-2">
                                CADASTRE QUANTOS MOTORISTAS QUISER
                            </h1>
                            <div className="block">
                                <p className="w-full  mx-4 md:mx-0 md:w-10/12 whitespace-break-spaces">
                                    <span className=" text-base md:text-lg">
                                        <strong>O número de cadastro de motorista é ilimitado.</strong>   Porém quem tem o controle da quantidade desse número é o gestor.
                                        Os motoristas também podem fazer <strong>solicitações</strong> de alguma informação/alteração para o gestor.
                                    </span>
                                </p>
                            </div>
                            <div className="my-5 flex justify-center md:justify-normal">
                                <button
                                    onClick={handleButtonClick}
                                    className="text-gradientButton font-bold px-8 py-4 rounded-full hover:scale-110  duration-500">
                                    QUERO SABER MAIS
                                </button>

                            </div>
                        </div>
                        <img
                            id='qtdMotorista'
                            className='hidden md:block w-4/12'
                            src={qtdMotorista}
                            alt=""
                        />
                    </div>

                </div>
            </div>
        </>
    )
}
